import React from 'react'

import Layout from "../components/layout"
import Blog from '../components/blog'

const blogData = require('./../../content/blog_data.json')

const blogs = []
blogData.forEach(blog => {
  blogs.push({
    title: blog.title,
    created: blog.created,
    body: blog.body,
    tags: blog.tags
  })
})

blogs.sort((a, b) => {
  if (a.created === b.created) {
    return 0
  }

  // descending
  return a.created < b.created ? 1 : -1
})

const IndexPage = () => {
  return (
    <Layout>
      {blogs.map((blog, idx) => {
        let divider = null
        if (idx > 0) {
          divider = <hr/>
        }
        return (
          <React.Fragment key={idx}>
            {divider}
            <Blog {...blog} key={idx}/>
          </React.Fragment>
        )
      })}
    </Layout>
  )
}

export default IndexPage
